import React, { useState, useEffect } from 'react';
import './CreateCV.css';

const CreateCV = ({ onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    educationHistory: [
      {
        degree: '',
        school: '',
        city: '',
        state: '',
        start: '',
        end: '',
      },
    ],
    jobs: [
      {
        company: '',
        position: '',
        start: '',
        end: '',
        describe: [''],
      },
    ],
    leadershipActivities: [
      {
        organization: '',
        role: '',
        city: '',
        state: '',
        start: '',
        end: '',
        description: '',
      },
    ],
    technical: '',
    languages: '',
    laboratory: '',
    interests: '',
  });

  // Cập nhật formData nếu có initialData
  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleArrayChange = (index, field, value, arrayName) => {
    const updatedArray = [...formData[arrayName]];
    updatedArray[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      [arrayName]: updatedArray,
    }));
  };

  const addNewItem = (arrayName, defaultValues) => {
    setFormData((prevData) => ({
      ...prevData,
      [arrayName]: [...prevData[arrayName], defaultValues],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Gửi formData lên App.js
  };

  return (
    <div className="create-wrapper">
      <div className="create-container">
        <form className="create-form" onSubmit={handleSubmit}>
          <div className="form__header">
            <h1>Build Your Resume</h1>
            <p>Fill out the fields below to generate your resume</p>
          </div>

          <h2>Personal Details</h2>
          <div className="form-group">
            <label>Full Name *</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Full Name"
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Address"
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone"
            />
          </div>
          <div className="form-group">
            <label>Email *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />
          </div>

          <h2>Education</h2>
          {formData.educationHistory.map((education, index) => (
            <div key={index} className="education-section">
              <h3>Education {index + 1}</h3>
              <div className="form-group">
                <label>Degree</label>
                <input
                  type="text"
                  value={education.degree}
                  onChange={(e) =>
                    handleArrayChange(index, 'degree', e.target.value, 'educationHistory')
                  }
                  placeholder="Degree"
                />
              </div>
              <div className="form-group">
                <label>School</label>
                <input
                  type="text"
                  value={education.school}
                  onChange={(e) =>
                    handleArrayChange(index, 'school', e.target.value, 'educationHistory')
                  }
                  placeholder="School"
                />
              </div>
              <div className="form-date-group">
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    type="month"
                    value={education.start}
                    onChange={(e) =>
                      handleArrayChange(index, 'start', e.target.value, 'educationHistory')
                    }
                  />
                </div>
                <div className="form-group">
                  <label>End Date</label>
                  <input
                    type="month"
                    value={education.end}
                    onChange={(e) =>
                      handleArrayChange(index, 'end', e.target.value, 'educationHistory')
                    }
                  />
                </div>
              </div>
            </div>
          ))}
          <button
            type="button"
            className="add-job-btn"
            onClick={() =>
              addNewItem('educationHistory', { degree: '', school: '', start: '', end: '' })
            }
          >
            Add Another Education
          </button>

          <h2>Work Experience</h2>
          {formData.jobs.map((job, index) => (
            <div key={index} className="job-section">
              <h3>Job {index + 1}</h3>
              <div className="form-group">
                <label>Company Name</label>
                <input
                  type="text"
                  value={job.company}
                  onChange={(e) => handleArrayChange(index, 'company', e.target.value, 'jobs')}
                  placeholder="Company Name"
                />
              </div>
              <div className="form-group">
                <label>Position</label>
                <input
                  type="text"
                  value={job.position}
                  onChange={(e) => handleArrayChange(index, 'position', e.target.value, 'jobs')}
                  placeholder="Position"
                />
              </div>
              <div className="form-date-group">
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    type="month"
                    value={job.start}
                    onChange={(e) => handleArrayChange(index, 'start', e.target.value, 'jobs')}
                  />
                </div>
                <div className="form-group">
                  <label>End Date</label>
                  <input
                    type="month"
                    value={job.end}
                    onChange={(e) => handleArrayChange(index, 'end', e.target.value, 'jobs')}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  value={job.describe.join('\n')}
                  onChange={(e) =>
                    handleArrayChange(index, 'describe', e.target.value.split('\n'), 'jobs')
                  }
                  placeholder="Enter each description on a new line"
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            className="add-job-btn"
            onClick={() =>
              addNewItem('jobs', { company: '', position: '', start: '', end: '', describe: [] })
            }
          >
            Add Another Job
          </button>

          <h2>Leadership & Activities</h2>
          {formData.leadershipActivities.map((activity, index) => (
            <div key={index} className="experience-section">
              <h3>Activity {index + 1}</h3>
              <div className="form-group">
                <label>Organization</label>
                <input
                  type="text"
                  value={activity.organization}
                  onChange={(e) =>
                    handleArrayChange(index, 'organization', e.target.value, 'leadershipActivities')
                  }
                  placeholder="Organization"
                />
              </div>
              <div className="form-group">
                <label>Role</label>
                <input
                  type="text"
                  value={activity.role}
                  onChange={(e) =>
                    handleArrayChange(index, 'role', e.target.value, 'leadershipActivities')
                  }
                  placeholder="Role"
                />
              </div>
              <div className="form-group">
                <label>Start Year</label>
                <input
                  type="text"
                  value={activity.start}
                  onChange={(e) =>
                    handleArrayChange(index, 'start', e.target.value, 'leadershipActivities')
                  }
                  placeholder="Start Year"
                />
              </div>
              <div className="form-group">
                <label>End Year</label>
                <input
                  type="text"
                  value={activity.end}
                  onChange={(e) =>
                    handleArrayChange(index, 'end', e.target.value, 'leadershipActivities')
                  }
                  placeholder="End Year"
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  value={activity.description}
                  onChange={(e) =>
                    handleArrayChange(index, 'description', e.target.value, 'leadershipActivities')
                  }
                  placeholder="Description"
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            className="add-job-btn"
            onClick={() =>
              addNewItem('leadershipActivities', {
                organization: '',
                role: '',
                city: '',
                state: '',
                start: '',
                end: '',
                description: '',
              })
            }
          >
            Add Another Leadership/Activity
          </button>

          <h2>Skills & Interests</h2>
          <div className="form-group">
            <label>Technical Skills</label>
            <textarea
              name="technical"
              value={formData.technical}
              onChange={handleChange}
              placeholder="List computer software and programming languages"
            />
          </div>
          <div className="form-group">
            <label>Languages</label>
            <textarea
              name="languages"
              value={formData.languages}
              onChange={handleChange}
              placeholder="List foreign languages and your level of fluency"
            />
          </div>
          <div className="form-group">
            <label>Laboratory Techniques (if applicable)</label>
            <textarea
              name="laboratory"
              value={formData.laboratory}
              onChange={handleChange}
              placeholder="List scientific or research lab techniques or tools"
            />
          </div>
          <div className="form-group">
            <label>Interests</label>
            <textarea
              name="interests"
              value={formData.interests}
              onChange={handleChange}
              placeholder="List activities you enjoy that may spark interview conversation"
            />
          </div>

          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateCV;
