import React, { useState, useEffect } from 'react';
import './App.css';
import UploadForm from './components/UploadForm';
import CreateCV from './components/CreateCV';
import RenderCV from './components/RenderCV';

function App() {
  const [currentPage, setCurrentPage] = useState('uploadForm');
  const [cvData, setCvData] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const savedData = localStorage.getItem('formData');
      console.log('Saved data:', savedData);
      if (savedData) {
        setCvData(JSON.parse(savedData));
        setCurrentPage('previewCV');
      }
    }
  }, []);

  const handleNavigation = (page) => {
    if (page === 'previewCV' && !cvData) {
      alert("No CV data found. Please fill out the form first.");
      setCurrentPage('createCV');
    } else {
      setCurrentPage(page);
    }
  };

  const handleCreateCVSubmit = (data) => {
    if (data) {
      setCvData(data);
      localStorage.setItem('formData', JSON.stringify(data));
      handleNavigation('previewCV');
    }
  };

  const handleEditCV = () => {
    handleNavigation('createCV');
  };

  return (
    <div className="App">
      {currentPage === 'uploadForm' && <UploadForm onNavigate={handleNavigation} />}
      {currentPage === 'createCV' && (
        <CreateCV 
          onSubmit={handleCreateCVSubmit} 
          onNavigate={handleNavigation} 
          initialData={cvData}
        />
      )}
      {currentPage === 'previewCV' && cvData && (
        <RenderCV 
          formData={cvData} 
          onEdit={handleEditCV} 
          onNavigate={handleNavigation}
        />
      )}
    </div>
  );
}

export default App;
