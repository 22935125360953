/* global wpApiSettings */
import React, { useState } from 'react';
import './UploadForm.css';

function UploadForm({ onNavigate }) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsSubmitting(true);

    if (!fullName || !email || !file) {
      setMessage('Please fill all fields and upload a file.');
      setIsSubmitting(false);
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setMessage('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    if (!validFileTypes.includes(file.type)) {
      setMessage('Invalid file type. Please upload a PDF or DOC/DOCX file.');
      setIsSubmitting(false);
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      setMessage('File size exceeds 2MB. Please upload a smaller file.');
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fullName', fullName);
    formData.append('email', email);

    try {
      const response = await fetch('https://vietnamjobs.online/wp-json/custom/v1/upload', {
        method: 'POST',
        headers: {
          'X-WP-Nonce': wpApiSettings.nonce,
        },
        body: formData,
      });

      if (!response.ok) {
        setMessage('Error uploading file. Please try again later.');
        setIsSubmitting(false);
        return;
      }

      const jsonData = await response.json();

      if (jsonData.message) {
        setMessage(jsonData.message);
      } else {
        setMessage('File uploaded successfully!');
        setFullName('');
        setEmail('');
        setFile(null);
      }
    } catch (error) {
      setMessage('Error uploading file. Please try again later.');
      console.error('Upload failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-wrapper">
      <div className="form-container">
        <h2>Submit Your Application</h2>
        <form onSubmit={handleSubmit} className="create-form">
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Enter your full name"
            required
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />

          <label htmlFor="file">Upload Resume</label>
          <input
            type="file"
            id="file"
            accept=".pdf, .doc, .docx"
            onChange={handleFileChange}
            required
          />

          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit Application'}
          </button>
        </form>

        {message && <div className="message">{message}</div>}

        <p className="create-cv" onClick={() => onNavigate('createCV')}>
          You don't have a CV? Go to Create CV
        </p>
      </div>
    </div>
  );
}

export default UploadForm;
