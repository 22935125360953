import React from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "./RenderCV.css";

const RenderCV = ({ formData, onEdit, onNavigate }) => {
  if (typeof onNavigate !== "function") {
    console.error("onNavigate is not a function");
    return null;
  }

  const downloadPDF = async () => {
    const cvElement = document.querySelector(".cv-render-container");

    try {
      const canvas = await html2canvas(cvElement, { scale: 3 });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`${formData.name}-CV.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleNavigate = () => {
    if (typeof onNavigate === "function") {
      console.log("Navigating to uploadForm");
      onNavigate("uploadForm");
    } else {
      console.error("onNavigate is not defined or not a function");
    }
  };

  return (
    <div>
      <div className="cv-render-container">
        <div className="cv-header">
          <h1 className="cv-name">{formData.name}</h1>
          <p className="cv-contact">
            {formData.address} • {formData.phone} • {formData.email}
          </p>
        </div>

        <div className="cv-section">
          <h2 className="section-title">Education</h2>
          {formData.educationHistory.map((education, index) => (
            <div key={index} className="education-section">
              <div className="education-row">
                <div className="education-left">
                  <p className="education-school">{education.school}</p>
                  <p className="education-degree">
                    {education.degree}, {education.concentration || ""}
                  </p>
                  {education.details && (
                    <p className="education-details">{education.details}</p>
                  )}
                </div>
                <div className="education-right">
                  <p className="education-location">{education.location}</p>
                  <p className="education-dates">
                    {education.start} - {education.end}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="cv-section">
          <h2 className="section-title">Experience</h2>
          {formData.jobs.map((job, index) => (
            <div key={index} className="job-section">
              <div className="job-row">
                <div className="job-left">
                  <p className="job-company">{job.company}</p>
                  <p className="job-title">{job.position}</p>
                  <ul className="job-description">
                    {Array.isArray(job.describe) &&
                      job.describe.map((desc, i) => (
                        <li key={i}>{desc}</li>
                      ))}
                  </ul>
                </div>
                <div className="job-right">
                  <p className="job-dates">{job.start} - {job.end}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {formData.leadershipActivities && (
          <div className="cv-section">
            <h2 className="section-title">Leadership & Activities</h2>
            {formData.leadershipActivities.map((activity, index) => (
              <div key={index} className="activity-section">
                <div className="activity-row">
                  <div className="activity-left">
                    <p className="activity-role">{activity.role}</p>
                    <p className="activity-organization">
                      {activity.organization}
                    </p>
                    {activity.description && (
                      <ul className="activity-description">
                        {Array.isArray(activity.description)
                          ? activity.description.map((desc, i) => (
                              <li key={i}>{desc}</li>
                            ))
                          : <li>{activity.description}</li>}
                      </ul>
                    )}
                  </div>
                  <div className="activity-right">
                    <p className="activity-dates">
                      {activity.start} - {activity.end}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="cv-section">
          <h2 className="section-title">Skills & Interests</h2>
          {formData.technical && (
            <div className="cv-flex-row">
              <span className="cv-label">
                <strong>Technical:</strong>
              </span>
              <span className="cv-content">{formData.technical}</span>
            </div>
          )}
          {formData.languages && (
            <div className="cv-flex-row">
              <span className="cv-label">
                <strong>Languages:</strong>
              </span>
              <span className="cv-content">{formData.languages}</span>
            </div>
          )}
          {formData.laboratory && (
            <div className="cv-flex-row">
              <span className="cv-label">
                <strong>Laboratory:</strong>
              </span>
              <span className="cv-content">{formData.laboratory}</span>
            </div>
          )}
          {formData.interests && (
            <div className="cv-flex-row">
              <span className="cv-label">
                <strong>Interests:</strong>
              </span>
              <span className="cv-content">{formData.interests}</span>
            </div>
          )}
        </div>
      </div>

      <div className="cv-actions">
        <button onClick={downloadPDF} className="btn-download">
          Download as PDF
        </button>
        <button onClick={onEdit} className="btn-edit">
          Edit CV
        </button>
      </div>

      <p className="cv-description" onClick={handleNavigate}>
        Are you ready to submit your CV to us or your desired company? If you need
        to update or create a CV, go back to the Upload form.
      </p>
    </div>
  );
};

export default RenderCV;
